<template>
  <div style="height: 100%; width: 100%">
    <HomeView v-if="res.data?.ui_style_set=='TestAllType'" :res="res"/>
    <KetPaper v-else-if="res.data?.ui_style_set=='ZLHY@A2KET-SPEAKING'" :res="res"/>
    <CommonPaper v-else-if="res.data && bUseCommonPaper" :res="res"/>
	  <HunanPaper v-else-if="res.data?.ui_style_set=='hunan-listening&speaking'" :res="res"/>
	  <HippoPaper v-else-if="res.data?.ui_style_set=='ZLHY@Hippo'" :res="res"/>
	  <A1MoverPaper v-else-if="res.data?.ui_style_set=='ZLHY@A1Mover'" :res="res"/>
	  <HomeView v-else-if="res.data" :res="res"/>
  </div>
</template>

<script>
import { getPaper} from "../api/index";
import HippoPaper from "./page/HippoPaper.vue";
import A1MoverPaper from "./page/A1MoverPaper.vue";
import HomeView from "./page/HomeView.vue";
import HunanPaper from "./page/HunanPaper.vue";
import CommonPaper from "@/views/page/CommonPaper.vue";
import KetPaper from "@/views/page/KetPaper.vue";
export default {
  components: {
    KetPaper,
	  HunanPaper,
    HippoPaper,
    A1MoverPaper,
    CommonPaper,
    HomeView
  },
  data() {
    return {
      res:{},
      bUseCommonPaper: true
    };
  },
  created() {
    this.stopF5Refresh();
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      // document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  },
  mounted() {
    this.handleParamsAndToken();
    this.paperList(true);
  },

  destroyed() {
    window.removeEventListener("beforeunload", (e) => {
      this.beforeunloadHandler(e);
    });
  },

  methods: {
    handleParamsAndToken: function () {
      let bNeedHref = false;
      let params = {};
      if(this.$route.query.user_id) {
        params.user_id = Number(this.$route.query.user_id); //用户id
        localStorage.setItem('exam.user_id', params.user_id);
        bNeedHref = true;
      } else {
        params.user_id = Number(localStorage.getItem('exam.user_id'));
      }

      if(this.$route.query.pid) {
        params.pid = this.$route.query.pid;  //合作方id
        localStorage.setItem('exam.pid', params.pid);
      } else {
        params.pid = localStorage.getItem('exam.pid');
      }

      if(this.$route.query.token) {
        params.token = this.$route.query.token;
        localStorage.setItem('exam.token', params.token);
        bNeedHref = true;
      } else {
        params.token = localStorage.getItem('exam.token');
      }

      if(this.$route.query.paper_id) {
        params.paper_id = this.$route.query.paper_id;
      }
      if(this.$route.query.examinee_id) {
        params.examinee_id = this.$route.query.examinee_id;
      }

      if(bNeedHref) {
        const r = this.$router.resolve({
          path: '/',
          query: {
            user_id: params.user_id,
            pid: params.pid,
            paper_id: params.paper_id,
            examinee_id: params.examinee_id
          }
        });
        window.location.href = r.href;
      }
    },
    // 获取试卷
    async paperList() {
      const res = await getPaper({
        user_id: Number(this.$route.query.user_id), //用户id
        pid: this.$route.query.pid, //合作方id
        paper_id: Number(this.$route.query.paper_id), //试卷id
        examinee_id: Number(this.$route.query.examinee_id), //考试场次id：0=则发起一次新的答题；>0表示继续某次未完成的答题
      });
      if (res.code == 0) {
		    this.res = res;
        document.title = res.data.title;
      } else {
        this.$message.error("试卷获取失败 "+(res.msg||''));
      }
    },

    // 禁止刷新
    stopF5Refresh() {
      document.onkeydown = function (e) {
        var evt = window.event || e;
        var code = evt.keyCode || evt.which;
        //屏蔽F1---F12
        if (code > 111 && code < 123) {
          if (evt.preventDefault) {
            evt.preventDefault();
          } else {
            evt.keyCode = 0;
            evt.returnValue = false;
          }
        }
      };
      //禁止鼠标右键菜单
      document.oncontextmenu = function () {
        return false;
      };
      //阻止后退的所有动作，包括 键盘、鼠标手势等产生的后退动作。
      history.pushState(null, null, window.location.href);
      window.addEventListener("popstate", function () {
        history.pushState(null, null, window.location.href);
      });
    },

    beforeunloadHandler(e) {
      e = e || window.event;
      if (e) {
        e.returnValue = "您是否确认离开此页面-您输入的数据可能不会被保存";
      }
      return "您是否确认离开此页面-您输入的数据可能不会被保存";
    },
  },
};
</script>
<style lang="less" scoped>
.test-clock-container {
  font: normal 14px "Helvetica Neue", Helvetica, sans-serif;
  user-select: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
  background: radial-gradient(ellipse at center, #969696 0%, #595959 100%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Scantron {
  position: fixed;
  z-index: 1000;
  top: 10%;
  left: 89%;
  // height: 949px;
  // width: 1867px;
  height: 80%;
  width: 80%;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 0 20px black;
  display: flex;
  border: 3px solid #575757;

  // padding-left: 50px;
  .contain {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    width: 96%;
    padding: 28px 0 0;

    .top {
      border-bottom: 3px solid rgb(237, 2, 140);
      padding-bottom: 6px;

      .box {
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 3px dashed rgb(237, 2, 140);

        .time {
          display: flex;
          align-items: center;

          img {
            width: 90px;
            height: 90px;
            margin-right: 10px;
          }
        }

        .title {
          font-size: 45px;
          font-weight: 600;
          color: rgb(237, 2, 140);
          text-align: center;
          width: 260px;
        }

        .rights {
          display: flex;
          align-items: center;

          .btn {
            cursor: pointer;
            width: 180px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            color: #fff;
            background-color: rgb(237, 2, 140);
            border-radius: 10px;
            font-size: 30px;
            font-weight: 600;
          }

          span {
            margin-left: 25px;
            display: inline-block;
            font-size: 66px;
            text-decoration: underline;
            font-style: italic;
          }
        }
      }

      .boxs {
        padding: 0;
      }
    }

    .bottom {
      height: 80%;
      display: flex;

      .btns {
        height: 100%;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;

        &::-webkit-scrollbar {
          display: none;
          /* Chrome Safari */
        }

        .item {
          margin-top: 50px;
          margin-left: 7px;
          cursor: pointer;
          width: 230px;
          height: 90px;
          background: #9e9e9e;
          border-radius: 10px;
          text-align: right;
          padding-right: 10px;
          box-sizing: border-box;
          position: relative;
          line-height: 90px;
          font-weight: 600;
          color: #fff;
        }

        .score {
          position: absolute;
          top: -5px;
          left: -5px;
          height: 100px;
          width: 190px;
          text-align: center;
          line-height: 100px;
          background: #ccc;
          border-radius: 20px;
          margin-bottom: 40px;
          font-size: 30px;
          font-weight: 600;
          color: #fff;
        }

        .active {
          background: rgb(237, 2, 140);
        }
      }

      .anwser {
        margin-top: 30px;
        margin-left: 15px;
        width: 85%;
        box-sizing: border-box;
      }
    }
  }

  .left,
  .right {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 160px;
    height: 320px;
  }

  .left {
    left: -58px;

    img {
      width: 59px;
      height: 319px;
    }
  }

  .right {
    right: -61px;

    img {
      width: 161px;
      height: 320px;
    }
  }
}

.ifream-boxs {
  z-index: 10;
  position: absolute;
  box-shadow: 0 0 5px black;
  right: 3%;
  bottom: -33%;
  width: 46%;
  height: 40%;
  background: #fff;

  img {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translateX(-50%);
    width: 64px;
    height: 37px;
    z-index: 9999;
  }

  .isUp {
    transform: translateX(-50%) rotate(180deg);
  }
}

.toleft {
  animation: moveL 1s;
  left: 10%;
}

.toright {
  animation: moveR 1s;
  left: 89%;
}

.toUp {
  animation: moveUp 1s;
  bottom: 5%;
}

.toDown {
  animation: moveDown 1s;
  bottom: -33%;
}

@keyframes moveR {
  0% {
    left: 10%;
  }

  100% {
    left: 89%;
  }
}

@keyframes moveL {
  0% {
    left: 89%;
  }

  100% {
    left: 10%;
  }
}

@keyframes moveUp {
  0% {
    bottom: -33%;
  }

  100% {
    bottom: 5%;
  }
}

@keyframes moveDown {
  0% {
    bottom: 5%;
  }

  100% {
    bottom: -33%;
  }
}

@media (max-width: 640px) {
  .Scantron .contain .top .box .title {
    width: 60px;
  }

  .Scantron .contain .top .box .rights .btn {
    width: 45px;
    height: 25px;
    line-height: 25px;
  }

  .Scantron .contain .top .box .rights span {
    font-size: 20px;
  }

  // .Scantron .contain .top .box .time {
  //   width: 75px;
  //   height: 20px;
  // }
  .Scantron .contain .bottom {
    display: block;
    height: 93%;
  }

  .Scantron .contain .bottom .btns {
    flex-direction: unset;
    height: 60px;
  }

  .Scantron .contain .bottom .anwser {
    margin-left: unset;
    width: 100%;
    height: 100%;
  }

  .Scantron .contain .bottom .btns .item {
    width: 55px;
    height: 50px;
    flex-shrink: 0;
    margin-right: 7px;
    margin-left: 0;
    border-radius: 3px;
    line-height: 78px;
    text-align: center;
    font-size: 13px;
  }

  .Scantron .contain .bottom .btns .score {
    height: 30px;
    line-height: 30px;
    width: 55px;
    z-index: 999;
    top: 0;
    left: 0;
  }

  .ifream-boxs {
    right: 9%;
    width: 80%;
  }

  .ifream-boxs img {
    width: 30px;
    height: 24px;
  }
}
</style>
